import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import React, {useState, useContext } from "react"
import Axios from "axios"
import { SocketContext } from '../context/socket';
import { useNavigate } from 'react-router-dom';
import { setGlobalState } from "../context/global"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {CardMedia} from "@mui/material";

const theme = createTheme();


export function SignIn() {
    const [ has_error, setHasError ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState("")
    
    const socket = useContext(SocketContext);
    const navigate = useNavigate()


    const handleSubmit = async (event) => {
        event.preventDefault();


        const data = new FormData(event.currentTarget);
        console.log(data.get('password'));
        Axios.post("https://chat.headlightsolutions.ro/auth/login", {
                email: data.get('email'),
                password: data.get('password')
            }).then( res =>{
                setGlobalState("auth_user", res.data.user) 
                socket.emit("user_connected", res.data.user.id)
                navigate('/users', { replace: true });
            }).catch(function (error) {
                if (error.response) {
                    setHasError(true)
                    setErrorMessage(error.response.data)
                }
            });
    };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            <CardMedia
                component="img"
                alt="Image"
                image="logo.png"
            />
            <Typography component="h6" variant="h6"  style={{ fontSize: '10px',fontWeight:'bold' }} align="center">
                Sistem de comunicații ce utilizează terminale securizate și noduri de comunicații blockchain
            </Typography>

            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            HLS Communication
          </Typography>

            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {has_error? 
            <Typography component="h1" variant="h6" color="#cc0000" align="center">
            {errorMessage}
            </Typography>:null
            }
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}