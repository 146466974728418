import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import TextField from "@material-ui/core/TextField"
import AssignmentIcon from "@material-ui/icons/Assignment"
import PhoneIcon from "@material-ui/icons/Phone"
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useRef, useState, useContext } from "react"
import Peer from "simple-peer"
import io from "socket.io-client"
import { setGlobalState, useGlobalState } from "../context/global"
import { SocketContext } from '../context/socket';
import Typography from '@mui/material/Typography';

export function Call() {
    const [ me, setMe ] = useState("")
	const [ stream, setStream ] = useState()
	const [ receivingCall, setReceivingCall ] = useState(false)
    const [ calling, setCalling ] = useState(false)
	const [ caller, setCaller ] = useState("")
	const [ callerSignal, setCallerSignal ] = useState()
	const [ callAccepted, setCallAccepted ] = useState(false)
	const [ idToCall, setIdToCall ] = useState("")
	const [ callEnded, setCallEnded] = useState(false)
	const [ name, setName ] = useState("")
	const myVideo = useRef()
	const userVideo = useRef()
	const connectionRef= useRef()

    

    const [chat_user] = useGlobalState("chat_user")
    const [auth_user] = useGlobalState("auth_user")
    const socket = useContext(SocketContext);
    const navigate = useNavigate()

    const {state} = useLocation();

    useEffect(() => {
        if (state != null) {
            setReceivingCall(true)
			setCaller(state.from)
			setName(state.name)
			setCallerSignal(state.signal)
        } else {
            setName(chat_user.name)
        }

        
        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
                setStream(stream)
                //console.log(myVideo)
                setTimeout(() => {
                    console.log(myVideo)
                    myVideo.current.srcObject = stream
                }, 10);
        })            

		socket.on("endCall", () => {
            console.log("endCall");
            const stream = myVideo.current.srcObject
            if (stream != null) {
                const tracks = stream.getTracks();
                for (let i = 0; i < tracks.length; i++) {
                    let track = tracks[i];
                    track.stop();
                }
                myVideo.current.srcObject = null;
            }

            socket.removeAllListeners();

            setCallEnded(true)
            if (connectionRef.current != null)
		        connectionRef.current.destroy()
            navigate('/users');
            //navigate(-1);
		})

        setTimeout(() => {
            //callUser()
        }, 50);

	}, [])

    const callUser = () => {
        console.log("calling")
        setCalling(true)
		const peer = new Peer({
			initiator: true,
			trickle: false,
			stream: stream
		})
		peer.on("signal", (data) => {
			socket.emit("callUser", {
				receiver_id: chat_user.id,
				signalData: data
			})
		})
		peer.on("stream", (stream) => {
    		userVideo.current.srcObject = stream
		})
		socket.on("callAccepted", (signal) => {
            console.log("call Accepted")
			setCallAccepted(true)
			peer.signal(signal)
		})

		connectionRef.current = peer
	}

    //callUser(chat_user.id)

    const answerCall =() =>  {
		setCallAccepted(true)
		const peer = new Peer({
			initiator: false,
			trickle: false,
			stream: stream
		})
		peer.on("signal", (data) => {
            console.log("answerCall "  + caller)
			socket.emit("answerCall", { signal: data, to: caller })
		})
		peer.on("stream", (stream) => {
			userVideo.current.srcObject = stream
		})

		peer.signal(callerSignal)
		connectionRef.current = peer
	}

    const leaveCall = () => {
        const stream = myVideo.current.srcObject
        if (stream != null){
            const tracks = stream.getTracks();
            for (let i = 0; i < tracks.length; i++) {
                let track = tracks[i];
                track.stop();
            }
        }
        myVideo.current.srcObject = null;

		setCallEnded(true)
		connectionRef.current.destroy()
        const to = (caller != "")?caller:chat_user.id;
        socket.emit("endCall", { to: to })
        socket.removeAllListeners();
        navigate('/users');
        //navigate(-1);
	}

    

    return (<>
    <link rel="stylesheet" href="call.css" />
   <h1 style={{ textAlign: "center", color: '#fff' }}>HLS</h1>
		<div className="container">

			<div className="caller">
				{
					callAccepted?(<h1 >{name}</h1>)
						:((caller == "")?(<h1 >Calling {name}</h1>):(<h1 >{name} is calling...</h1>))
				}

				{receivingCall && !callAccepted ? (
					<>

						<Button variant="contained" color="primary" onClick={answerCall}>
							Răspunde
						</Button>
					</>
				) : null}
				<Button variant="contained" color="secondary" onClick={leaveCall}>
					Închide
				</Button>
				{!calling && !receivingCall ? (
					<IconButton color="primary" aria-label="call" onClick={() => callUser()}>
						<PhoneIcon fontSize="large" />
					</IconButton>
				): null}
			</div>

			<div className="video-container">
				<div className="video">
					{stream &&  <video playsInline muted ref={myVideo} autoPlay style={{ width: "300px" }} />}
				</div>
				<div className="video">
					{callAccepted && !callEnded ?
					<video playsInline ref={userVideo} autoPlay style={{ width: "300px"}} />:
					null}
				</div>
			</div>

		</div>
    </>)
}