import { Route, Routes } from 'react-router-dom';
import { SocketContext, socket } from './context/socket';
import { Users } from "./pages/Users"
import { Chat } from "./pages/Chat"
import { GlobalChat } from "./pages/GlobalChat"
import { Call } from "./pages/Call"
import { SignIn } from "./pages/SignIn"
import './App.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';


function App() {

  const [userList, setUserList] = useState(1)
  const navigate = useNavigate()

  socket.on("callUser", (data) => {
    //socket.off("users_list");
    navigate('/call', { replace: true, state: data });        
  })

  return <SocketContext.Provider value={socket}>
      <Routes>
        <Route path="/" element={<SignIn/>}/>
        <Route path="/users" element={<Users/>}/>
        <Route path="/chat" element={<Chat/>}/>
        <Route path="/global-chat" element={<GlobalChat/>}/>
        <Route path="/call" element={<Call/>}/>
      </Routes>
  </SocketContext.Provider>
  
}

export default App;
