import React, {useContext, useEffect, useRef, useState} from "react"
import {useNavigate} from 'react-router-dom';
import {useGlobalState} from "../context/global"
import IconButton from '@mui/material/IconButton';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {SocketContext} from '../context/socket';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from 'react-string-avatar';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Phone from '@mui/icons-material/Phone';
import {Box, Stack} from "@mui/system";
import Button from '@mui/material/Button';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SendIcon from '@mui/icons-material/Send';
import {styled} from "@mui/material";
const CryptoJS = require("crypto-js");
const BoldSpan = styled('span')({
    fontWeight: 'bold',
});

const theme = createTheme();
let encryptKey = ""


export function GlobalChat() {
    const [auth_user] = useGlobalState("auth_user")
    const socket = useContext(SocketContext);
    const navigate = useNavigate()
    const dummy = useRef()
    const inputFileRef = useRef()
    //const backdropRef = useRef()
    //const modalContentRef = useRef()
    const [openImage, setOpenImage] = useState(false);
    const [openJson, setOpenJson] = useState(true);

    const [imageSource, setImageSource] = useState("");
    const [jsonSource, setJsonSource] = useState();


    const [messages, setMessages] = useState([]);


    function processNewMessages(received_chat) {
        console.log("Received messages")

        const chat = JSON.parse(received_chat);

            setMessages(chat.messages.map((encryptedMessage) => {
                console.log("Decode,", encryptKey)
                if (!encryptedMessage.is_file) {
                    encryptedMessage.text = CryptoJS.AES.decrypt(encryptedMessage.text, encryptKey).toString(CryptoJS.enc.Utf8);
                }
                return encryptedMessage
            }))


            setTimeout(() => {
                dummy.current.scrollIntoView({behavior: 'smooth'});


            }, 200);

    }

    useEffect(() => {
        if (auth_user == null) {
            navigate('/', {replace: true});
        }

        socket.emit("get_global_chat", { sender_id: auth_user?.id})

        socket.on("chat_initialized", (received_chat) => {
            const chat = JSON.parse(received_chat);
            encryptKey = chat.key
            processNewMessages(received_chat);

        })

        socket.on("global_messages", (received_chat) => {
            processNewMessages(received_chat);
        })

    }, []);


    function handleBackClick() {
        socket.off("messages");
        socket.off("chat_initialized");

        navigate('/users', {replace: true});
    }


    const [formValue, setFormValue] = useState('');

    function sendMessage() {
        const ciphertext = CryptoJS.AES.encrypt(formValue, encryptKey).toString();


        socket.emit("send_global_message", {
            message: ciphertext
        })
        setFormValue('');
    }

    const fieldKeyDown = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            console.log("Encode key", encryptKey)
            sendMessage();
        }
    }

    return (<>
        {/* <div className="App">
        <link rel="stylesheet" href="chat.css" /> */}
        <link rel="stylesheet" href="layout.css"/>


        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <AppBar
                position="absolute"
                color="default"
                elevation={1}
                sx={{
                    height: "70px", position: 'relative', borderBottom: (t) => `1px solid ${t.palette.divider}`,
                }}>
                <Toolbar sx={{justifyContent: "space-between"}}>
                    <Typography variant="h6" color="black" noWrap>
                        <ListItem sx={{py: 1, px: 2}}>
                            <IconButton onClick={handleBackClick}><ArrowBack/></IconButton>
                            <Avatar string="Global Chat"
                                    bgColor="green"
                                    roundShape="true"/>
                            <ListItemText primary="Global Chat" sx={{py: 0, px: 2}}/>
                        </ListItem>
                    </Typography>
                </Toolbar>
            </AppBar>

            <Box sx={{backgroundColor: "white"}}>
                <Box elevation={0} sx={{
                    height: "calc(100vh - 140px)",
                    width: "100%",
                    overflowY: "scroll",
                    backgroundColor: "white",
                    boxShadow: "none"
                }}>
                    {messages && messages.map(msg => <ChatMessage key={msg._id} message={msg} openImage={openImage}
                                                                  setOpenImage={setOpenImage}
                                                                  setImageSource={setImageSource}
                                                                  openJson={openJson} setOpenJson={setOpenJson}
                                                                  setJsonSource={setJsonSource}/>)}
                    <span ref={dummy}></span>
                </Box>

                <Stack direction="row" spacing={2}>
                    <TextField value={formValue} onKeyDown={fieldKeyDown}
                               onChange={(e) => setFormValue(e.target.value)} placeholder="Message" sx={{
                        width: "90%",
                        border: 'none',
                        "& fieldset": {border: 'none'},
                        backgroundColor: "lightgray",
                        borderRadius: "30px",
                        marginLeft: "10px",
                        marginBottom: "10px",
                        marginTop: "10px",
                        height: "50px",
                        boxShadow: "none"
                    }}>
                    </TextField>

                    <Button onClick={(e) => sendMessage()} variant="contained"
                            style={{
                                marginTop:"10px",
                                marginRight:"10px"
                            }}
                            sx={{marginLeft: "10px", height: "50px", margin: "40px"}}>
                        <SendIcon></SendIcon>
                    </Button>
                </Stack>
            </Box>


        </ThemeProvider>
    </>)
}

function ChatMessage(props) {
    const [auth_user] = useGlobalState("auth_user")
    const {text, from, is_file, file_type,username} = props.message;
    var open = props.openImage;
    var setOpen = props.setOpenImage;
    var setSource = props.setImageSource;
    const messageClass = from === auth_user.id ? 'sent' : 'received';
    var source = "https://chat.headlightsolutions.ro/auth" + text;



    return (<>
        <div className={`message ${messageClass}`}>

            {is_file ? (<p style={{ cursor: "pointer" }} onClick={(e) => {
                const link = document.createElement('a');
                link.href = source;
                link.setAttribute(
                    'download',
                    text,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

            }}>
                {text} <FileDownloadIcon></FileDownloadIcon></p>) : <p>
                <BoldSpan>  {username}</BoldSpan> {text}</p>}
        </div>
    </>)
}
