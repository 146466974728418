import React, {useEffect, useContext } from "react"
import { setGlobalState, useGlobalState } from "../context/global"
import { SocketContext } from '../context/socket';

import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from 'react-string-avatar';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Power from '@mui/icons-material/Power';

import ErrorBoundary from '../components/ErrorBoundary'

const theme = createTheme();

export function Users() {
    const [users_list] = useGlobalState("users_list")
    const [auth_user] = useGlobalState("auth_user")
    const socket = useContext(SocketContext);
    const navigate = useNavigate()

    const handleListItemClick = async (event) => {
        setGlobalState("chat_user", event)
        //socket.removeAllListeners();
        socket.off("users_list");
        navigate('/chat', { replace: true });
    }

    const handleListItemClickGC = async (event) => {
        setGlobalState("chat_user", event)
        //socket.removeAllListeners();
        socket.off("users_list");
        navigate('/global-chat', { replace: true });
    }

    function handleSignoutClick() {
        socket.emit("signout")
        //socket.removeAllListeners();
        socket.off("users_list");
        navigate('/', { replace: true });        
    }



    useEffect(() => {
        if (auth_user == null) {
            navigate('/', {replace: true});
        }

        socket.on("users_list", user_list => {   
            var result = [];
            var json_user_list = JSON.parse(user_list);

            for(var i in json_user_list) {
                result.push(json_user_list[i])
            }

            setGlobalState("users_list", result);
        })   

        socket.on("disconnect", function() {   
            console.log("disconnected");
        })   

        // socket.on("callUser", (data) => {
        //     socket.off("users_list");
		// 	navigate('/call', { replace: true, state: data });        
		// })

        socket.emit("users_list")
    }, []);

    return (
    <ErrorBoundary>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppBar
                position="absolute"
                color="default"
                elevation={1}
                sx={{
                    position: 'relative',
                    height: "70px",
                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                }}>
                <Toolbar sx={{ justifyContent: "space-between" }}>
                    <Typography variant="h6" color="black" noWrap>
                        <ListItem sx={{ py: 1, px: 2 }}>
                            <ListItemText primary="HLS Chat" secondary={auth_user?.first_name + " " + auth_user?.last_name} sx={{ py: 0, px: 2 }}/>
                        </ListItem>
                    </Typography>
                    <IconButton onClick={handleSignoutClick}><Power /></IconButton>
                </Toolbar>
            </AppBar>

            <List disablePadding>
                <ListItem sx={{ py: 1, px: 2 }} onClick={() => handleListItemClickGC()}>
                    <Avatar string="Global CHAT" bgColor="green" roundShape="true"/>
                    <ListItemText primary="Global CHAT" sx={{ py: 0, px: 2 }}/>
                </ListItem>
                {users_list.map((user) => (
                <ListItem key={user.name} sx={{ py: 1, px: 2 }} onClick={() => handleListItemClick(user)}>
                    <Avatar string={user.name} bgColor={user.socket_id == null?"red":"green"} roundShape="true"/>
                    <ListItemText primary={user.name} sx={{ py: 0, px: 2 }}/>
                    
                    {user.messages_no != "0"?
                    <Typography variant="body2">
                        
                        <Avatar initials={user.messages_no} bgColor="green" width={20} roundShape="true"/>
                    </Typography>:""
                    }
                </ListItem>
                ))}


            </List>
        </ThemeProvider>
    </ErrorBoundary>
    )
}